import { Icon as CkIcon, IconProps as CkIconProps } from "@chakra-ui/react"
import { ComponentProps } from "react"

import { IconType } from "react-icons"

export type IconProps = CkIconProps & {
  as: IconType
}

export function Icon({ as, ...props }: IconProps) {
  return <CkIcon as={as as ComponentProps<typeof CkIcon>["as"]} {...props} />
}
